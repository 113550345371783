<template>
  <div class="product">
    <div class="row">
      <div class="col-4">
        <div class="card">
          <a href=""><img class="card-img-top" src="https://cf.shopee.co.th/file/0dbaf1a8c7c40ec528001757dbebbdb4" alt="Card image cap" /></a>
          <div class="card-body">
            <h5 class="card-title"><b>ลูกตีนเป็ด ขนาด 1-2 นิ้ว</b></h5>
            <p style="font-size:1.5rem">
              5 บาท
            </p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Product",
  props: {},
  data() {
    return {
      start: 0,
      end: "",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: black;
}
</style>
